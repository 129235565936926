<template>
  <div class="fullScreen show" v-loading="loading">
        <Crumbs>
            <el-breadcrumb-item  slot="after">
                历史劳动合同
            </el-breadcrumb-item>
        </Crumbs>
        <div class="fullScreenMain">
            <el-tabs class="crad" type="border-card">
                <el-tab-pane label="历史劳动合同">
                    <Annex :manageId="form.id"></Annex>
                </el-tab-pane>

            </el-tabs>
        </div> 
  </div>

</template>
<script>

export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
        Annex: () => import("./Annex.vue"),
    },

    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: false,
            submitLoading: false,
            form: {},

        };
    },
    inject:['re'],
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                // this.$emit('update:loading', false);
                this.showEditDialog = newVal;
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                this.form = newVal;
            }
        },
    },
    created() {

    },
    methods: {
      


      
    },
};
</script>

<style scoped lang='scss'>
@import "@/styles/edit.scss";
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 140px;
      height: 140px;
      display: block;
    }


</style>
